<template>

    <div class="hoverloading" style="position:fixed; width:100%; height:100vh; left:0; top:0; z-index:100000; background:#fff; padding:10%"
        v-if="isLoading">
        <Loader />
    </div>
    
    <div class="hoverloading" style="position:fixed; width:100%; height:100vh; left:0; top:0; z-index:100000; background:#fff; padding:10%; opacity:0.7"
        v-if="formsubmited">
        <Loader />
    </div>
    
    <div class="container">
      
      <section class="results">
        <div class="tiles-container-15">
          <div class="column">
            <router-link to="/" class="backlink">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
                <title>ionicons-v5-a</title>
                <polyline points="328 112 184 256 328 400" style="fill:none;stroke:#000;stroke-linecap:square;stroke-miterlimit:10;stroke-width:48px" />
              </svg>
              BACK TO RESULTS LIST</router-link>
          </div>
        </div>

        <div class="detailTopHead">
          <h1>{{ getSelectedCar.make }} {{ getSelectedCar.model }} {{ getSelectedCar.variant }} </h1>
          <div class="price">
            <h2>R {{ Number(parseInt(getSelectedCar.price)).toLocaleString() }}</h2>
          </div>
        </div>

        <vueper-slides class="mainslider" ref="vueperslides1" :touchable="false" fade :visible-slides="isMobile() ? 1 : 2"
            :autoplay="false" :bullets="false" 
            @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, {
                emit: false,
            })" 
            fixed-height="400px">

            <vueper-slide v-for="(slide, i) in getSelectedCar.imageUrls" :key="i" :image="slide">
            </vueper-slide>
            
        </vueper-slides>
  
        <div v-if="!isMobile()">
            <vueper-slides class="no-shadow thumbnailsilder" ref="vueperslides2" :visible-slides="11"
                fixed-height="75px" :bullets="false" :touchable="false" :gap="2.5" :arrows="false" 
                @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, {
                    emit: false,
                })">

                <vueper-slide v-for="(slide, i) in getSelectedCar.imageUrls" :key="i" :image="slide"
                @click="$refs.vueperslides2.goToSlide(i)">
                </vueper-slide>

            </vueper-slides>
        </div>
        <div v-else>
            <vueper-slides class="no-shadow thumbnailsilder" ref="vueperslides2" :visible-slides="4" 
                fixed-height="75px" :bullets="false" :touchable="false" :gap="2.5" :arrows="false" 
                :slide-ratio="1 / 8" :dragging-distance="50"
                @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, {
                    emit: false,
                })">

                <vueper-slide v-for="(slide, i) in getSelectedCar.imageUrls" :key="i" :image="slide.FullUrl"
                @click="$refs.vueperslides2.goToSlide(i - 1)">
                </vueper-slide>

            </vueper-slides>
        </div>

        <!-- <vueper-slides class="no-shadow thumbnailsilder" ref="vueperslides2" :visible-slides="imagesList.length" fixed-height="75px"
          :bullets="false" :touchable="false" :gap="2.5" :arrows="false" @slide="
            $refs.vueperslides1.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          ">
          <vueper-slide v-for="(slide, i) in imagesList" :key="i" :image="slide.FullUrl"
            @click="$refs.vueperslides2.goToSlide(i)">
          </vueper-slide>
        </vueper-slides> -->
  
        <div class="row mt-4">
          <div class="col-lg-6">
            <div class="list detailPage">
              <div class="list__items d-flex">
                <div class="list__item-val">Mileage:</div>
                <div class="list__item-val">
                  {{ getSelectedCar.mileageInKm }} KM
                </div>
              </div>
              <div class="list__items d-flex">
                <div class="list__item-val">Model year:</div>
                <div class="list__item-val">{{ getSelectedCar.year }}</div>
              </div>
              <div class="list__items d-flex">
                <div class="list__item-val">Full Service History:</div>
                <div class="list__item-val">
                  {{ getSelectedCar.serviceHistory }}
                </div>
              </div>
              <div class="list__items d-flex">
                <div class="list__item-val">Department:</div>
                <div class="list__item-val">
                  {{ getSelectedCar.vehicleCategory }}
                </div>
              </div>
              <!-- <div class="list__items d-flex">
                <div class="list__item-val">Body Style:</div>
                <div class="list__item-val">{{ getSelectedCar.bodyType }}</div>
              </div> -->
              <!-- <div class="list__items d-flex">
                <div class="list__item-val">Transmission:</div>
                <div class="list__item-val">{{ getSelectedCar.transmission }}</div>
              </div> -->
              <div class="list__items d-flex">
                <div class="list__item-val">Exterior Color:</div>
                <div class="list__item-val">
                  {{ getSelectedCar.colour }}
                </div>
              </div>
              <div class="list__items d-flex">
                <div class="list__item-val">Stock Number:</div>
                <div class="list__item-val">
                  {{ getSelectedCar.stockNumber }}
                </div>
              </div>
              <!-- <div class="list__items d-flex">
                <div class="list__item-val">VIN:</div>
                <div class="list__item-val">{{ getSelectedCar.VIN }}</div>
              </div> -->
  
            </div>
          </div>
          <div class="col-lg-6">
            <!-- <div class="row">
              <div class="col-lg-6">
                <div class="address">
                  <div class="address__title">GLENDALE NISSAN</div>
                  <div class="address__actual">
                    484 NORTH AVE GLENDALE HEIGHTS, IL 60139
                  </div>
                  <div class="phone__number">630-469-6100</div>
                  <div class="link">Nissan Certified Pre-Owned</div>
                  <div class="link__sticker">Original Window Sticker</div>
                  <div class="sticker">
                    <img
                      src="https://picsum.photos/100"
                      class="h-100 w-100"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <button class="results__cta d-block">CONTACT DEALER</button>
                <button class="results__cta d-block">BOOK A TEST DRIVE</button>
              </div>
            </div> -->
          </div>
        </div>
  
        <div class="mutedComment">
          {{ getSelectedCar.description }}
        </div>
  
        <div class="contactForm">
          <div class="headingGroup">
            <div class="heading d-flex">
              <span>Enquiry</span>
            </div>
          </div>
  
          <div class="formContact">
            <form method="post" @submit.prevent="postUser()">
              <div class="column block h4 uppercase headIn">
                Send Enquiry
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="column l50 m50 s100">
                    <label for="firstName" class="title">Name<span> *</span></label>
                    <div class="mdx-graphical-input">
                      <input v-model="form.name" type="text" id="firstName" name="firstName" placeholder="First Name" 
                        @click="checkInputField(getSelectedCar)" />
                    </div>
                    <div class="formerror" v-if="errors.name">{{ errors.name }}.</div>
                  </div>
                </div>
  
                <div class="col-md-6">
                  <div class="column l50 m50 s100">
                    <label for="lastName" class="title">Surname</label>
                    <div class="mdx-graphical-input">
                      <input v-model="form.surname" type="text" id="Surname" name="Surname" placeholder="Surname" 
                        @click="checkInputField(getSelectedCar)"  />
                      <div class="mdx-custom-error hide"></div>
                    </div>
                  </div>
                </div>
  
              </div>
  
              <div class="row">
                <div class="col-md-6">
                  <div class="column l50 m50 s100">
                    <label for="email" class="title">Email<span> *</span></label>
                    <div class="mdx-graphical-input">
                      <input v-model="form.email" type="email" id="email" name="email" placeholder="Email" 
                        @click="checkInputField(getSelectedCar)" />
                    </div>
                    <div class="formerror" v-if="errors.email">{{ errors.email }}</div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="column l50 m50 s100">
                    <label for="phone" class="title">Phone<span> *</span></label>
                    <div class="mdx-graphical-input">
                      <input v-model="form.phone" type="tel" id="phone" name="phone" placeholder="Phone" 
                        @click="checkInputField(getSelectedCar)" />
                    </div>
                    <div class="formerror" v-if="errors.phone">{{ errors.phone }}</div>
                  </div>
                </div>
              </div>
              
              <!-- <div class="row">
                  <div class="column l50 m50 s100">
                    <div class="graphical-select select">
                      <label for="phone" class="title">Select a Dealer<span> *</span></label>
                      <div class="mdx-graphical-input">
                        <select v-model="form.dealer" class="inputForm custom-select" 
                          @click="checkInputField(getSelectedCar)" 
                          required=""
                        >
                          <option value="">Select a dealer</option>
                          <option 
                            v-for="(dealer, index) in this.dealersList" 
                            :key="index"
                            :value="dealer.id"
                          >
                            {{dealer.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="formerror" v-if="this.errors.dealer">This field is required.</div>
                  </div>
              </div>

              <br />
              <hr /> -->

              <div class="row">
                <div class="column block">
                  <label for="message" class="title">Comments (optional)</label>
                  <div class="mdx-graphical-textarea">
                    <textarea v-model="form.comment" rows="5" cols="50" id="message" name="message" placeholder="Your comments" 
                        @click="checkInputField(getSelectedCar)"></textarea>
                    <div class="mdx-custom-error hide"></div>
                  </div>
                </div>
              </div>
  
              <div class="row">
                <div class="column block">
                  <div class="mdx-graphical-checkbox checkboxHero">
                    <input type="checkbox" id="checkbox" name="checkbox" v-model="form.optin">
                    <label for="checkbox">
                        <span class="mdx-checkbox"></span>
                        <span class="mdx-checkbox-label"> 
                            I'D LIKE TO RECEIVE MARKETING COMMUNICATION FROM NISSAN
                        </span>
                    </label>
                  </div>
                </div>
              </div>
  
              <br />
                <p class="column block textMuted">
                    We need to process your info to contact and assist you. This might entail additional processing to find
                    you alternate solutions. You can view your info and withdraw consent at any time by going to<br /><br />
                    <a href="https://www.nissanbrits.co.za/terms-and-conditions" class="ctaText" target="_blank">
                      www.nissanbrits.co.za/terms-and-conditions
                    </a>
                </p>

              <div class="column">
                <button type="button" class="btn btn-primary results__cta submit" title="Submit" @click="postUser()">
                  Submit
                </button>
              </div>

            </form>
          </div>
        </div>
      </section>
    </div>
</template>

<script>
import Loader from '../components/Cars/UI/Loader';
import axios from 'axios';

import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

import { mapGetters, mapActions } from "vuex";
import { adobeAnalyticCode } from "../analytics.js";
    
export default {
    name: "CarView",

    components: { 
        VueperSlides, 
        VueperSlide,
        Loader
    },
    data: function () {
      return {
        imageList: [],
        isLoading: true,
        formsubmited: false,
        form: {
            name: '',
            surname: '',
            phone: '',
            email: '',
            comment: '',
            stockID: "",
            optin: "",
            dealer: ""
        },
        formFirstFieldCheck: false,
        isFormFieldHasError: false,
        isFormSuccessful: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          dealer: ''
        },
        errors_attributes: {
          name: null,
          email: null,
          phone: null,
          dealer: null
        },
        error: false,
        dealersList: [
          {
            name: 'BRITS AUTO',
            id: 1389
          }
        ]
      }
    },
    computed: {

        ...mapGetters(["getSelectedCar"]),
    },
    mounted(){

        setTimeout(() => {
            // console.log(this.getSelectedCar, 'image list')
            this.imageList = this.getSelectedCar.imageUrls;

            if(this.getSelectedCar.length !== 0){
              // console.log('loading.. false')
              this.isLoading = false;
            }
        }, 1000);

    },
    created() {

        const id = this.$route.params.id.replace(/[^0-9]/g,'');
        this.$store.dispatch("fetchSelectedCar", id);

    },
    methods: {

        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        postUser() {
    
            if (this.form.name === '') {
              this.errors.name = 'Name field is required';
            }else{
              this.errors.name = '';
            }

            var phoneno = /^\d{10}$/;
            if (this.form.phone === '') {
              this.errors.phone = 'Phone number field is required';
            }else if(!(this.form.phone.match(phoneno))){
              this.errors.phone = 'Enter valid phone number';
            }else{
              this.errors.phone = '';
            }

            var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.form.email === '') {
              this.errors.email = 'Email field is required';
            }else if(!(this.form.email.match(validEmailRegex))){
              this.errors.email = 'Enter valid email address';
            }else{
              this.errors.email = '';
            }
            
            if(this.errors.name != '' || this.errors.email != '' || this.errors.phone != ''){

              // send the error
              this.isFormFieldHasError = true;
              this.errors_attributes = {
                name: 'name',
                email: 'email',
                phone: 'phone',
                dealer: 'dealer'
              }
              this.sendAdobeAnalytics(this.getSelectedCar);
              return false;
            }
           
    
            if (this.form.name != '' && this.form.phone != '' && this.form.email != '') {

                this.error = false;
                this.formsubmited = true;
                this.form.stockID = this.getSelectedCar.stockNumber;

                this.form.dealer = "0012400000gP1L0AAK";

                var inputData = {
                  listingId: this.getSelectedCar.id,
                  vehicle: this.getSelectedCar.make +' '+ this.getSelectedCar.model +' '+ this.getSelectedCar.variant,

                  model: this.getSelectedCar.model,
                  colour: this.getSelectedCar.colour,
                  price: this.getSelectedCar.price,
                  stockNumber: this.getSelectedCar.stockNumber,
                  mileage: this.getSelectedCar.mileageInKm,
                  year: this.getSelectedCar.year,

                  enquiryBy: this.form.name,
                  surname: this.form.surname,
                  contactNumber: this.form.phone,
                  emailAddress: this.form.email,
                  message: this.form.comment,
                  dealer: this.form.dealer,
                  receiveMarketing: this.form.optin,
                }

                axios.post("https://brits.nissansouthafrica.com/addLead.php", JSON.parse(JSON.stringify(inputData)))
                  .then(response => {
                    console.log(response);
                    this.formsubmited = false;

                    this.isFormSuccessful = true; //set true after form submit is successfull to update Adobe Analytics
                    this.sendAdobeAnalytics(this.getSelectedCar);
                    this.$router.push({ name: 'thankyou' });
                  })
                  .catch(error => {
                    this.formsubmited = false;
                    console.log(error);
                  });
        
            }else{
            
                this.isFormFieldHasError = true;
                this.errors_attributes = {
                  name: 'name',
                  email: 'email',
                  phone: 'phone',
                  dealer: 'dealer'
                }
                this.sendAdobeAnalytics(this.getSelectedCar);
            }
        },
    
        checkInputField: function (getSelectedCar) {
    
            if (!this.formFirstFieldCheck) {
    
                this.sendAdobeAnalytics(getSelectedCar);
                this.formFirstFieldCheck = true;
            }
        },
  
        sendAdobeAnalytics(getSelectedCar) {
    
            adobeAnalyticCode({
                'field_type': "vehicle-form",
                'isFormFieldHasError': this.isFormFieldHasError,
                'failedInputFields': this.errors_attributes,
                'isFormSubmitSuccessful': this.isFormSuccessful,
                'category': getSelectedCar.bodyType, //Body Style
                'make': getSelectedCar.make,
                'model': getSelectedCar.model,
                'vehicleid': getSelectedCar.id,
                'language': "en",
                'version_name': getSelectedCar.make +' '+ getSelectedCar.model + ' ' + getSelectedCar.variant,
            });
        },
  
    },
    
  };
  </script>

  <style>
  .formerror {
    display: block;
    width: 100%;
    font-size: 14px;
    color: #c3002f;
    text-align: left;
  }
  
  .vueperslides__arrow {
    display: none;
  }
  
  .list.detailPage .list__items {
    border-bottom: 1px solid #eee;
    padding: 3px 0;
  }
  
  .list.detailPage .list__items:last-child {
    border: 0;
  }
  
  .vueperslides--fixed-height.vueperslides--bullets-outside {
    margin-bottom: 0.55em;
  }
  
  .detailTopHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
  }
  
  .detailTopHead h1 {
    font-size: 34px;
    word-break: break-word;
    word-wrap: break-word;
    margin: 0;
  }
  
  .detailTopHead .price h2 {
    font-family: "Nissan Light";
    font-size: 22px;
    margin: 0;
  }
  
  .thumbnails {
    margin: auto;
    max-width: 300px;
  }
  
  .thumbnails .vueperslide {
    box-sizing: border-box;
    border: 1px solid #fff;
    transition: 0.3s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
  }
  
  .thumbnails .vueperslide--active {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-color: #000;
  }
  
  input,
  textarea,
  select {
    width: 100%;
    font-size: inherit;
    color: #343434;
    background: #fff;
    resize: none;
    border: 1px solid #666;
    font-weight: normal;
    border-radius: 0;
  }
  
  .mdx-graphical-input,
  .mdx-graphical-select {
    height: 50px;
    position: relative;
  }
  
  .mdx-graphical-input input,
  .mdx-graphical-input select,
  .mdx-graphical-select input,
  .mdx-graphical-select select {
    bottom: 0;
    left: 0;
    padding: 0 15px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
  
  form label.title,
  .form label.title {
    color: #343434;
    display: block;
    margin: 1px 0 1px;
    font-size: 13px;
    text-align: left;
    font-family: "Nissan Bold";
  }
  
  .formContact .row .column {
    margin-bottom: 21px;
  }
  
  .formContact input:focus,
  .formContact input:active,
  .formContact input:focus-visible,
  .formContact input:hover {
    border: 1px solid #c3002f;
    box-shadow: none;
    outline: 0;
  }
  
  .mdx-custom-error {
    /* background: #000; */
    bottom: calc(100% + 10px);
    color: #fff;
    left: 50%;
    min-width: 200px;
    max-width: 360px;
    padding: 20px;
    position: absolute;
    right: 0;
    transform: translate(-50%, 0);
    width: 100%;
    z-index: 90;
  }
  
  .mutedComment {
    font-size: 14px;
    text-align: left;
  }
  
  .contactForm {
    margin-top: 3.5em;
    width: 100%;
  }
  
  .ctaDetailWrap {
    display: flex;
    margin-top: 1.5em;
  }
  
  .ctaDetailWrap a {
    text-decoration: none;
    font-size: 13px;
    color: #c3002f;
    font-family: "Nissan Bold";
  }
  
  .ctaDetailWrap a:nth-child(2) {
    color: #343434;
    margin-left: 1em;
  }
  
  .formContact {
    margin-top: 2em;
  }
  
  .formContact .headIn {
    text-transform: uppercase;
    font-family: "Nissan Light";
    font-size: 21px;
    color: #343434;
    text-align: left;
    margin-bottom: 1em;
  }
  
  .dd-down {
    visibility: hidden;
    z-index: 0;
  }
  
  .block.methodBlock .col-md-6 {
    text-align: left;
  }
  
  .block.methodBlock .col-md-6 label {
    display: flex;
    margin-top: 7px;
  }
  
  input[name="preferred"],
  input[name="timeFrame"] {
    accent-color: #c3002f;
  }
  
  input[type="radio"] {
    border: 0px;
    width: 33px;
    height: 1.55em;
  }
  
  .block.methodBlock .mark {
    display: none;
  }
  
  .formContact .row .column.methodBlock {
    margin-bottom: 1px;
  }
  
  .purchaseBlock .col-md-6 {
    text-align: left;
    margin-bottom: 15px;
  }
  
  .purchaseBlock .col-md-6 .mark {
    display: none;
  }
  
  .purchaseBlock .col-md-6 label {
    display: flex;
    align-items: center;
  }
  
  .formContact textarea:focus,
  .formContact textarea:active,
  .formContact textarea:focus-visible,
  .formContact textarea:hover {
    border: 1px solid #c3002f;
    box-shadow: none;
    outline: 0;
  }
  
  .formContact textarea {
    padding: 11px;
  }
  
  .textMuted {
    font-size: 14px;
    text-align: left;
  }
  
  .ctaText {
    text-decoration: none;
    font-size: 13px;
    color: #c3002f;
    font-family: "Nissan Bold";
  }
  
  .ctaText:hover,
  .ctaText:focus {
    color: #c3002f;
  }
  
  .btn.submit {
    width: max-content;
  }
  
  .backlink {
    font-size: 12px;
    background: none;
    color: #000;
    padding: 15px 20px 15px 0;
    cursor: pointer;
    padding: 15px 0 15px 20px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    line-height: 1em;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    font-family: "nissan-regular";
  }
  
  .tiles-container-15 {
    margin: -15px;
    text-align: left;
    /* display: flex; */
  }
  
  .unstyle {
    font-weight: initial;
    font-style: initial;
    text-decoration: initial;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  .tiles-container-15>.column {
    display: inline-block;
    vertical-align: top;
    padding: 15px;
  }
  
  .checkboxHero {
    padding-left: 2em;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  
  .checkboxHero input:not(.reset) {
    position: absolute;
    left: 0;
    margin-left: 0;
    opacity: 0;
  }
  
  .checkboxHero input {
    width: 2em;
    flex-basis: 2em;
    margin-left: -2em;
    position: relative;
    vertical-align: baseline;
  }
  
  .checkboxHero label {
    font-size: 16px;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: nissan light;
  }
  
  .checkboxHero input[type=checkbox]:not(.reset)~label:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABq0lEQVRoge2Yu0oDQRSGvw1BsYmsL2DjpTLJahFTCFqpDxAFBfXhNKDpJcHC6BrUwsrCR7DWRtRkxybBRPZ+F85X7s7uzMc/zDkMCIIgCIIgCEEwqTZMymfXbBa9xmppLCgMJtUGqCZQBFp99IMtun2n8bkU+SMxwlWmkM7S/OMgAaDcvstVIi4SF330w3+xtaJIQE5EokpADkTikICMReKSgAxF4pSAjETiloAMRJKQAJ8F8YaK8UCtFGaCce6o7NtLqOYrS64tiBeeIj2MegG6X3y0o8iYVBsKTrFNYu54j9Yg7L/BY2v1MOoWVhsoASjU/RQzO+s8vgeZJKntNI5jIpcsTFtY5wwlADS0etBkktxO47gmYrKyBoUrQJ9Ygs9k0khihOepFVYmTQnwefwGlUlbAgLUEb8yWUhAwILoJfPN53YWEhCispuUaxpaR8Hs5Bv1AtoitqfT8lHUOuFFqBbFKRkbEk9iROhey4dMahIQsWl0kUlVAiLeomzw/ARqV4O336fxVmy/xNLG31JeHR4AnQH6SdoSsdLDmFc5uAMQBEEQBEHIET8KSvd829WRDwAAAABJRU5ErkJggg==);
  }
  
  .checkboxHero input:not(.reset)~label:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    vertical-align: top;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid #666;
    background-color: #fff;
    background-size: 100% 100%;
    background-position: 0 1.5em;
    background-repeat: no-repeat;
    box-shadow: 0 0 0 0 rgb(43 154 255 / 20%);
    transition: box-shadow .15s, background-position .15s;
  }
  
  .checkboxHero input:not(.reset):checked~label:before {
    background-position: 0 0;
  }
  
  
  @media only screen and (max-width: 600px) {
    .detailTopHead h1 {
      font-size: 25px;
    }
  
    .mainslider .vueperslide {
      width: 100% !important;
      left: 0 !important;
      background-size: contain;
      background-repeat: no-repeat;
    }
  
    /* .thumbnailsilder .vueperslide:nth-child(odd) {} */
  }
  </style>